import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/app/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["AccountMenu"] */ "/app/apps/diamond-chest/src/components/AccountMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastWrapper"] */ "/app/apps/diamond-chest/src/components/feedback/ToastWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSelector"] */ "/app/apps/diamond-chest/src/components/LanguageSelector.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/i18n/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/app/apps/diamond-chest/src/providers/feature-flags-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionLogoutHandler"] */ "/app/apps/diamond-chest/src/providers/SessionLogoutHandler.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/diamond-chest/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/.pnpm/next-auth@5.0.0-beta.22_next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1__nodemailer@6.9.15_react@18.3.1/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.21.1_next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../TeleNeoVariable.woff2\"}],\"variable\":\"--font-teleneo\"}],\"variableName\":\"TeleNeo\"}");
